<template>
  <div>
    <v-card class="pb-4" translate="no">
      <v-card-title class="d-flex align-center justify-space-between">
          <span>
            Author <span class="text--disabled text-subtitle-1" v-if="totalCount">({{totalCount}})</span>
          </span>
      </v-card-title>
      <v-row class="px-2 ma-0 py-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="2"
          class="d-flex align-center justify-space-between"
        >
          
          <v-checkbox
            v-model="params.hasDomain"
            hide-details
            class="mt-0 pt-0 pb-2"
            label="DOMAIN"
            @change="getAuthorList"
          ></v-checkbox>

            <v-checkbox
              v-model="params.hasEns"
              hide-details
              label="ENS"
              class="ml-2 pt-0 pb-2 mt-0"
              @change="getAuthorList"
            ></v-checkbox>
          </v-col>

        <v-col
          cols="12"
          sm="2"
          class="py-0"
        >
          <v-select v-if="reputationOptions"
            v-model="params.reputation"
            placeholder="Select Reputation"
            :items="reputationOptions"
            item-text="name"
            item-value="code"
            outlined
            clearable
            dense
            hide-details
            label="REPUTATION"
            @change="getAuthorList"
          ></v-select>
        </v-col>
      
        <!-- search -->
        <v-col
          class="py-0"
          cols="12"
          sm="3">
        <v-text-field
          v-model="params.address"
          placeholder="Search by address"
          outlined
          hide-details
          dense
          label="ADDRESS"
          @change="getAuthorList"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" 
      class="py-0"
      sm="3">
        <v-text-field
          v-model="params.displayName"
          placeholder="Search by Name"
          outlined
          hide-details
          dense
          label="NAME"
          @change="getAuthorList"
        >
        </v-text-field>
      </v-col>

      <v-col
          cols="12"
          sm="2"
          class="py-0"
        >
          <v-select
            v-model="params.sortBy"
            placeholder="Sort By"
            :items="sortByOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            label="SORT BY"
            @change="getAuthorList"
          ></v-select>
        </v-col>
      </v-row>

      <v-skeleton-loader
                type="list-item-three-line, list-item-three-line, list-item-three-line"
                :loading="true"
                v-if="!authorList"
      >
      </v-skeleton-loader>
      <v-simple-table class="mt-0" v-else>
        <thead>
            <tr>
                <th v-for="(item, index) in authorListHeaders" :key="index" class="text-no-wrap text-body-2 font-weight-black">{{item}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in authorList" :key="index">
                <td>
                  <div class="d-flex jusify-start align-center py-4">
                    <v-avatar class="me-3" size="45" v-if="item.avatarUrl">
                      <v-img :src="item.avatarUrl"></v-img>
                    </v-avatar>

                    <v-avatar class="me-3" size="45" v-else>
                      <v-icon>{{icons.mdiAccountOutline}}</v-icon>
                    </v-avatar>                    

                    <div class="d-flex flex-column" style="max-width:120px">
                      <a @click="showMirrorPage(item.address)" class="text-body-2 text-primary text-truncate" target="_blank">
                          <v-icon v-if="item.domain" size="12" class="mr-1" color="success">{{icons.mdiWeb}}</v-icon>
                          {{item.displayName}} 
                      </a>
                      <div class="d-flex align-center justify-start">
                        <span class="text-caption text--disabled">{{$tools.shortAddress(item.address)}}</span>
                        <v-btn icon small class="ml-1 mt-1" target="_blank" :href="`/post?author=${item.address}&language=&authorFilter=ALL&startDate=`" >
                          <v-icon size="16" color="warning">{{icons.mdiFilterOutline}}</v-icon>
                        </v-btn>
                      </div>
                      
                    </div>  
                  </div>
                </td>

                <td>
                  <span class="text-body-1">{{item.totalPost}}</span>
                </td>

                <td>
                  <div class="d-flex flex-column">
                    <span class="text-body-2">{{$tools.formatTimestamp(item.firstPostAt)}}</span>
                    <div class="text-caption text--disabled"><timeago :datetime="$tools.formatTimestamp(item.firstPostAt)" :auto-update="60"></timeago></div>
                  </div>
                </td>

                <td>
                  <div class="d-flex flex-column">
                    <span class="text-body-2">{{$tools.formatTimestamp(item.lastPostAt)}}</span>
                    <div class="text-caption text--disabled"><timeago :datetime="$tools.formatTimestamp(item.lastPostAt)" :auto-update="60"></timeago></div>
                  </div>
                </td>

                <td>
                   <span class="text-subtitle-1">{{item.ens || '-'}}</span>
                </td>

                <td>
                   <v-chip small :color="reputationColors[item.reputation]">{{reputationName[item.reputation]}}</v-chip>
                </td>

                <td>
                  <v-icon
                    small
                    class="me-2"
                    @click="editAuthor(item.address)"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>


                  <v-tooltip bottom color="success">
                    <template #activator="{ on, attrs }">
                      <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          color="success"
                          class="me-2"
                          @click="setAuthorQuickReputation(item.address,'OK')"
                        >
                          {{ icons.mdiEmoticonHappy }}
                      </v-icon>
                    </template>
                    <span>Mark as OK</span>
                  </v-tooltip>

                  <v-tooltip bottom color="info">
                    <template #activator="{ on, attrs }">
                      <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          color="info"
                          class="me-2"
                          @click="setAuthorQuickReputation(item.address,'NEUTRAL')"
                        >
                          {{ icons.mdiEmoticonNeutral }}
                      </v-icon>
                    </template>
                    <span>Mark as NEUTRAL</span>
                  </v-tooltip>

  
                  <v-tooltip bottom color="error">
                    <template #activator="{ on, attrs }">
                      <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          color="error"
                          class="me-2"
                          @click="setAuthorQuickReputation(item.address,'SPAM')"
                        >
                          {{ icons.mdiEmoticonDead }}
                      </v-icon>
                    </template>
                    <span>Mark as SPAM</span>
                  </v-tooltip>

                </td>
            </tr>
        </tbody>
    </v-simple-table>
    <v-divider></v-divider>
    <div class="my-8" v-if="pageNum > 1">
        <v-pagination v-model="params.page" circle :total-visible="8" :length="pageNum" @input="getAuthorList" 
        class=""></v-pagination>
    </div>
    </v-card>


    <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card v-if="authorInfo" translate="no">
      <v-card-title class="mb-0">
        <span class="headline">
          <a :href="'https://mirror.xyz/' + authorInfo.address" class="text-primary " target="_blank">
                {{authorInfo.displayName}} 
            </a>
          / Update Author Profile </span>

        <span class="text-caption text--disabled mt-2">
        <a :href="'https://etherscan.io/address/' + authorInfo.address" class="text-info " target="_blank">
          {{authorInfo.address}} 
        </a>
        </span>
      </v-card-title>
    
      <v-card-text class="mt-2">
        <v-container >
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                label="TAGS"
                type="text"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                :items="reputationOptions"
                item-text="name"
                item-value="code"
                hide-details
                label="REPUTATION"
                v-model="authorInfo.reputation"
                placeholder="Select Reputation"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="setAuthorreputation"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="isFullPageVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile v-if="authorInfo">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="isFullPageVisible = false"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-body-1">
          <a :href="'https://mirror.xyz/' + authorInfo.address"  class="text-body-1 white--text" target="_blank">
            https://mirror.xyz/{{authorInfo.address}}
          </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="setAuthorQuickReputation(authorInfo.address,'OK')"
          >
            <v-icon class="me-2" color="success">{{ icons.mdiEmoticonHappy }}</v-icon>
            Mark AS OK
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="setAuthorQuickReputation(authorInfo.address,'SPAM')"
          >
            <v-icon class="me-2" color="error">{{ icons.mdiEmoticonDead }}</v-icon>
            Mark AS SPAM
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="setAuthorQuickReputation(authorInfo.address,'NEUTRAL')"
          >
            <v-icon class="me-2" color="info">{{ icons.mdiEmoticonNeutral }}</v-icon>
            Mark AS NEUTRAL
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear
            indeterminate
            v-show="iframeLoading"
            color="primary"
            class="mt-0"
      ></v-progress-linear>
      
      <!-- v-card-text full-height -->
      <v-card-text class="mt-5" style="height:100%;">
        <iframe id="bdIframe" 
        @load="iframeLoading = false"
        :src='("https://mirror.xyz/" + authorInfo.address)' style="width:100%;height:100%;border:none;"></iframe>
      </v-card-text>
      
    </v-card>
  </v-dialog>


  </div>
</template>

<script>
import {mdiAccountOutline,mdiFilterOutline,mdiWeb,mdiEmoticonHappy,mdiEmoticonDead,mdiPencilOutline,mdiClose,mdiEmoticonNeutral} from '@mdi/js';
import { authorList, authorReputations,authorSetReputation } from '../request/author'

export default {
  components: {
    
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmoticonDead,
        mdiEmoticonNeutral,
        mdiPencilOutline,
        mdiWeb,
        mdiEmoticonHappy,
        mdiClose,
        mdiFilterOutline
      },
      authorListHeaders: [
        "NAME","POSTS","FIRST-POST","LAST-POST","ENS","REPUTATION","ACTIONS"
      ],
      authorList: null,
      reputationOptions: null,
      sortByOptions : [
        { title: 'Recently Created', value: 'JOINTIME' },
        { title: 'Recently Actived', value: 'ACTIVE' },
        { title: 'Most Creative', value: 'TOTALPOST' },
      ],
      params: {
        reputation: 0,
        hasDomain: null,
        hasEns: true,
        sortBy: 'ACTIVE',
        address: null,
        displayName: null,
        page:1,
        size:30
      },
      reputationColors: {
        0: 'secondary',
        1: 'info',
        2: 'success',
        3: 'warning',
        4: 'error',
        5: 'error',
      },
      reputationName : {
        0: 'UNKNOWN',
        1: 'NEUTRAL',
        2: 'OK',
        3: 'SUSPICIOUS',
        4: 'UNSAFE',
        5: 'SPAM',
      },
      pageNum: 1,
      totalCount:0,
      authorInfo:null,
      isDialogVisible:false,
      isFullPageVisible:false,
      iframeLoading:true
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
      let params = this.$route.query;
      for(let key in params) {
        if(this.params[key] !== undefined) {
          this.params[key] = params[key];
        }
      }
      this.getAuthorReputations();
      this.getAuthorList();
  },

  methods: {

    async showMirrorPage(address){
      this.isFullPageVisible = true;
      this.iframeLoading = true;
      this.authorInfo = this.authorList.find(item => item.address === address);
      
      setTimeout(function() {
            const oIframe = document.getElementById("bdIframe");
            console.log(oIframe);
            const deviceWidth = document.documentElement.clientWidth;
            const deviceHeight = document.documentElement.clientHeight;
            oIframe.style.width = Number(deviceWidth);
            oIframe.style.height = Number(deviceHeight) - 120 + "px"; 
        }, 1000);
    },

    async setAuthorQuickReputation(address,reputation){

      let authorInfo = this.authorList.find(item => item.address === address);
      let reputationCode =  this.reputationOptions.find(item => item.name === reputation).code;
      
      //get name by reputation code
      await authorSetReputation({address:address,reputation:reputationCode}).then(res=>{
          this.$q.success('Set Reputation Success');
          authorInfo.reputation = reputationCode;
      }).catch(err=>{
          this.$q.error(err);
      })

      this.isFullPageVisible = false;
    },

    async setAuthorreputation(){
      if(!this.authorInfo){
        return;
      }

      //get name by reputation code
      await authorSetReputation({address:this.authorInfo.address,reputation:this.authorInfo.reputation}).then(res=>{
          this.$q.success('Set Reputation Success');
          this.isDialogVisible = false;
      }).catch(err=>{
          this.$q.error(err);
          this.isDialogVisible = false;
      })
    },

    editAuthor(address) {
        this.authorInfo = this.authorList.find(item => item.address === address);
        this.isDialogVisible = true;
    },  

    async getAuthorList() {

      //remove hasDomain hasEns if is false
      let params = this.params;
      if(!params.hasDomain){
        delete params.hasDomain;
      }
      if(!params.hasEns){
        delete params.hasEns;
      }

      await authorList(this.params).then(res => {
          if (res.status === 0) {
            this.authorList = res.data.resultList;
            var totalCount = res.data.totalCount;
            this.totalCount = totalCount;
            this.pageNum = totalCount > 0 ? ((totalCount < this.params.size) ? 1 : ((totalCount % this.params.size) ? (parseInt(totalCount / this.params.size) + 1) : (totalCount / this.params.size))) : 0;
          }else {
            this.$q.error(res.message);
          }
      }).catch(err => {
        this.$q.error(err);
      });
    },

    async getAuthorReputations() {
      await authorReputations().then(res => {
        if (res.status == 0) {
          this.reputationOptions = res.data
        } else {
          this.$q.error(res.message)
        }
      }).catch(err => {
        this.$q.error('Failed to get data!')
        console.log(err)
      })
    },
  },
}
</script>

<style scoped>
.dropbox {
  border: 1px dashed #4C8BF5;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
}
</style>
